.contact-page {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
}

.contact-page-banner {
    width: 100%;
    //background-image: url("/MyApp/Assets/Images/8.jpg");
    background-size: cover;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}


.contact-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    position: absolute;
    top: 70px;

    h1 {
        font-size: 44px;
        line-height: 44px;
        font-weight: 400;
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-bottom: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div {
        position: relative;
        margin: 0 auto;
        width: 50px;
        border-top: 1px solid #fff;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    p {
        margin: 0px;
        margin-bottom: 20px;
        white-space: normal !important;
        text-align: center;
        opacity: .8;
        font-size: 80%;
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }


}


.contactpage-middle {
    background-color: #fff;
    width: 100%;
    max-height: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 10px 95px;
    box-sizing: border-box;
    margin-bottom: 94px;
}


.contact-middle-left {
    width: 49%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}



.contact-midle-right {
    width: 49%;
    height: auto;
}

.contact-middle-box {
    width: 100%;
    height: auto;
    padding: 10px 15px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    input {
        width: 100%;
        height: 30px;
        border: none;
        outline: none;
        padding: 0px 10px;
        font-size: 13px;
        color: #000;
        font-weight: 300;
        letter-spacing: .2px;
        border-bottom: 1.2px solid #ccc;
        box-sizing: border-box;
    }

    input:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        outline: none !important;
        border-color: rgba(255, 255, 255, 0.6);

    }

    label {
        font-size: 15px;
        color: #000;
        font-style: normal;
        font-weight: 300;
    }
}


.tell-us-everything {
    color: #4d4d4d;
    border-bottom: 1px solid #ccc !important;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    font: 13px / 22px 'Open Sans', sans-serif;
    height: 75px;
    margin-top: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;

    &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        outline: none !important;
        border-color: rgba(255, 255, 255, 0.6);
    }
}


.contact-middle-submit {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 100%;
        height: auto;
        padding: 9px 13px;
        font-size: 14px;
        font-weight: 300;
        background-color: #E67E22 !important;
    }
}

.contact-midle-right {
    width: 49%;
    height: auto;
    padding: 14px 20px;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    padding-top: 0px;

    h2 {
        color: #343434;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 20px;
        text-align: left;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;
    }


}

.contact-all-info {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

}

.contact-info-single {
    width: 100%;
    display: flex;
    margin-bottom: 14px;
    align-items: start;
    justify-content: start;

    div {

        width: 40px !important;
        height: 40px !important;
        text-align: center;
        border: 1px solid #4d4d4d;
        font-size: 14px;
        padding: 3px;
        line-height: 40px;
        width: auto;
        height: auto;
        color: #E67E22;
    }

    p {
        width: auto;
        font-size: 14px;
        margin: 0px;
        margin-left: 17px;
        font-weight: 400;
        color: #737373;
    }
}

.contact-info-map {
    width: 100%;
    height: 450px;
}


.contact-pop-up {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation-name: open;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    transition: all ease-in-out;
}

@keyframes open {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;
    }
}

.contact-pop-box {
    width: 51%;
    min-height: auto;
    padding: 20px 15px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    background-color: white;
    position: relative;
}

.contact-pop-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    h2 {
        margin: 0px;
        margin-bottom: 9px;
        font-style: normal !important;
        color: #21294a;
        -webkit-font-smoothing: antialiased;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        -webkit-text-size-adjust: 100%;
        font-size: 21px;
    }

    p {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        color: #3f486d;
        font-size: 14px;
        font-weight: 450;
        margin-bottom: 14px;
    }
}


.bottom-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        background-color: #f5f5f5 !important;
        text-align: left;
        color: inherit !important;
        padding: 0 14px !important;
        line-height: inherit !important;
        border: 0 !important;
        border-radius: inherit !important;
        height: 46px;
        font-size: 18px;
        border: 1.2px solid #ccc !important;
        width: 77%;
        border-radius: 8px !important;
        outline: none;
    }

}


.subscribe-btn {
    width: auto;
    height: 46px;
    font-size: 18px;
    font-weight: 450px;
    background-color: #000;
    color: white;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 8px;
}


.contact-cross-btn {
    position: absolute;
    width: auto;
    height: auto;
    padding: 10px;
    border-radius: 100%;
    background-color: white;
    top: -10px;
    right: -60px;
    font-size: 23px;
    font-weight: 450px;
    color: #000;
    padding-left: 20px;
    padding-right: 20px;
}

.contact-pop-close {
    opacity: 0;
    animation-name: close;
    animation-duration: 200ms;
    animation-iteration-count: 1;

}

.contact-relative-page{
    position: relative;
    display: flex;
    justify-content: center;
}



@keyframes close {
    0% {
        opacity: 1;
        transition: all 200ms ease-in-out;
    }

    100% {
        opacity: 0;
        transition: all 200ms ease-in-out;

    }
}