.partners-main {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 10000;
    top: 0;
    background-color: white;
    // overflow-y: scroll;
}


.partners-main-black {
    width: 100%;
    min-height: 30px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        color: white;
        font-size: 18px;
        font-style: normal;
        margin: 0;
        padding: 10px 0px;
        font-size: 15px;
        font-weight: 600;
    }
}

.partners-main-second {
    width: 100%;
    min-height: 40px;
    display: flex;
    padding: 0px 80px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1.9px solid #ccc;
}



.partners-logo-div {
    width: 30%;
    height: auto;

    img {
        width: 200px;
        height: auto;
    }
}


.partners-button {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 20px;

    button {
        width: auto;

        height: auto;
        font-size: 14px;
        color: #000;
        background-color: transparent !important;
        border-bottom: 1px solid transparent;
    }
}


.partners-numbers {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        margin: 0px !important;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: start;
        border-right: 1.7px solid #ccc;
        padding: 0px 13px;
        gap: 2px;
        font-weight: 500;
        font-size: 15px;
        font-style: normal;

        p {
            margin: 0px;
        }

    }

    span {
        width: auto;
        height: auto;
        font-size: 16px;
        padding-right: 4px;
    }
}


.thirdlevel {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: end;
    overflow-y: scroll;
    height: 500px;
}

.thirdlevel-first-div {
    box-sizing: border-box;
    width: 60%;
    height: 100%;
    // border: 1px solid black;
}

.thirdlevel-second-div {
    position: sticky;
    top: 0;
    width: 28%;
    height: 100%;
    padding: 80px 30px 30px;
    background-color: #baf7e9cc;
}





.title {

    font-weight: 500;
    color: #49aa9a;
    color: #000;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Work Sans, sans-serif;
    height: auto;
    font-size: 20px;
    margin-bottom: 0px;
    font-style: normal;
}

.thirdlevel-form {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.msg-form {
    font-size: 12px;
    color: #000;
    margin: 0px;
    width: 100%;
    padding: 8px 0px;
    text-align: center;
    font-size: 17px;

}

.both-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    div {
        font-size: 14px;
        color: #000;
    }
}


.greenbox-all-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
        border-radius: 35px;
        margin-bottom: 15px;
        width: 220px;
        outline: none;
        padding: 12px 20px;
        border: 1.6px solid #ccc;
        text-align: center;

        font-size: 15px;
        font-family: Work Sans, sans-serif;
        font-style: normal;
        font-weight: 400;
    }
}


.greenbox-bottom {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        margin: 0px;

        font-size: 14px;
        font-weight: 500;
        font-style: normal !important;

    }
}


.greenbox-button-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    font-size: 16px;
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}


.submitbtn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: auto;
        height: auto;
        margin-top: 20px;
        padding: 10px 18px;
        border-radius: 35px;
        background-color: #49aa9a;
        color: white !important;
        font-family: Work Sans, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .08em;
        white-space: normal;
        font-size: 14px;

        &:hover {
            background-color: #3a867a;
        }
    }
}


.landlord-main {
    width: 100%;
    min-height: 100%;
    //overflow-y: scroll;
    max-height: fit-content;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 0px 140px;
    background-color: #f9f9f9;
    padding-bottom: 100px;
}

.landlord-main-top {
    height: auto;
    width: 100%;
    border-bottom: 1.7px solid #ccc;
    height: 300px;
}

.landlord-main-bottom {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
}

.landlord-main-left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    gap: 15px;
    // border: 1px solid red;
}

.landlord-main-right {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    height: auto;
    gap: 15px;
    // border: 1px solid red;
}

.landlord-input-box {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 49%;
    margin-left: 0px;

    input {
        height: 30px;
        width: 240px;
        max-width: 100%;
        border: 1.3px solid #939393;
        outline: none;
        padding: 3px 10px;
        border-radius: 4px;
    }

    label {
        width: auto;
        height: auto;
        padding-left: 20px;
    }

    select {
        height: 30px;
        width: 260px;
        max-width: 100%;
        border: 1.3px solid #939393;
        outline: none;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
        color: #9187a0;
    }

    option {
        color: #49aa9a;
        padding: 4px 10px;

    }

    textarea {
        width: 260px;
        min-height: 150px;
        max-height: 400px;
        padding: 15px 20px;
        box-sizing: border-box;
    }
}


.div-for-gap {
    width: 100%;
    padding: 45px 0px;
}


.submit-button {
    width: 100%;
    height: auto;
    padding: 30px 15px;
    background: transparent;

    button {
        width: auto;
        height: auto;
        padding: 10px 18px;
        background-color: #3a867a;
        font-size: 14px;
        font-weight: 650;
        font-style: normal;
        letter-spacing: .1rem;
    }
}


.label-landlord {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: start;
    color: #49aa9a;
}