@import "../css/Variable.scss";

.cart-product-for-two-row {
  width: 98% !important;
  margin: 5px auto;
  min-height: 180px;

  .product-detail-button-theme3-cart-button {
    font-size: 13px;
    padding: 2px;
  }

  .productswap1 {
    width: 140px !important;
    height: 80px;
  }

  .productswap2 {
    width: 150px !important;
    height: 80px;
  }

  .productswap3 {
    width: 140px !important;
    height: 80px;
  }

  .card-product-image-1 {
    height: 125px;
  }

  .card-product-details-1 {
    text-align: left;
    margin-left: 13px;
    margin-right: 5px;
  }

  .product-rating-box {
    bottom: 2px;
  }

  .Card-product-type-1-img {
    height: 150px;
    margin-bottom: 0;
  }

  .card-product-name {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .card-product-price {
    font-size: 11px;
    line-height: 12px;
    margin-top: 7px;
  }

  .card-product-price-2 {
    font-size: 10px;
    line-height: 12px;
    margin-top: 5px;
    padding: 0 7px;
  }

  .card-product-desc {
    font-size: 10px;
    margin-top: 5px;
    line-height: 13px;
  }

  .product-rating-box {
    width: 80px;
    padding: 1px;
    left: 15px;
    display: none;
  }

  .card-menu-favorite {
    position: absolute;
    top: 7px;
    font-size: 16px;
    right: 9px;
    z-index: 1;
    border: 0;
  }

  .Card-product-type-1-top {
    min-height: 90px;
  }

  .Card-product-type-1-rating-color-box {
    display: flex;
    justify-content: space-between;
    width: 95%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3px;
    margin-top: 2px;
  }

  .Card-product-type-1-rating-box11 {
    width: max-content;
    padding: 3px 6px 3px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
    margin-bottom: 0px;
    border-radius: 15px;
    margin-left: 2px;
    background: #eaeaff;
  }

  .Card-product-type-1-desc-box label {
    margin-top: -7px;
  }

  .Card-product-type-1-end {
    height: 25px;
    background: linear-gradient(90deg, $Theme-color, lighten($Theme-color, 8%));
    margin-top: 5px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    font-weight: 100;
    font-size: 9px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: rgb(255, 255, 255);
  }

  .outline-coloroption-c2 {
    width: 13px;
    height: 13px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 50%;
    padding: 2px;
    margin-right: 3px;
  }

  .color-option-box1-c2 {
    background-color: rgb(0, 0, 0);
  }

  .color-option-box2-c2 {
    background-color: rgb(175, 175, 175);
  }

  .color-option-box3-c2 {
    background-color: rgb(0, 179, 255);
  }

  .color-option-box4-c2 {
    background-color: rgb(85, 85, 85);
  }

  .color-option-box5-c2 {
    background-color: rgb(182, 8, 8);
  }

  .color-option-box-main {
    width: 13px;
    height: 13px;
    border-radius: 50%;
  }

  .Card-product-type-1-rating-box {
    width: 85px;
    height: 11px;
    margin-bottom: 4px;
  }

  .productcart-bottom-txt-box {
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
  }

  .product-cart-3-txt-3 {
    font-size: 11px;
    margin-right: 3px !important;
  }

  .product-cart-3-txt-4 {
    font-size: 9px;
    margin-right: 6px !important;
  }

  .product-cart-3-txt-5 {
    font-size: 10px;
  }

  .product-cart-3-txt-6 {
    font-size: 10px;
  }

  .product-cart-3-txt-7 {
    font-size: 9px;
  }

  .Card-product-type-1-end1 {
    font-size: 9px;
    height: 23px;
    padding-left: 0px;
  }

  .card-4-text-box-1 p {
    margin: 0;
  }

  .card-product-details-2 {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .card-4-text-box-1-txt-1 {
    color: rgb(85, 85, 85);
    font-size: 13px;
  }

  .card-4-text-box-1-txt-2 {
    color: rgb(85, 85, 85);
    font-size: 12px;
    font-weight: 100;
    line-height: 13px;
  }

  .card-4-text-box-1-txt-3 {
    color: rgb(0, 0, 65);
    font-size: 12px;
    margin-top: 3px !important;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 15px;
  }

  .card-product-price11 {
    font-size: 11px;
    margin-top: 5px;
  }

  .discount-percentage {
    font-size: 9px;
    margin-top: 5px;
    padding: 4px;
  }
  .Card-product-type-1-desc-box {
    width: 95%;
  }
}

.cart-product-for-List {
  width: 92% !important;
  margin: 5px auto;
  min-height: max-content;
  flex-direction: row;
  padding-bottom: 50px;
  align-items: center;
  padding-top: 10px;
  a {
    width: 100%;
    text-decoration: none;
    display: flex;
}

  .Card-product-type-1-cart5 {
    background: linear-gradient(to right, rgb(249, 234, 186), rgb(239, 239, 239));
    margin-top: 10px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    font-weight: 500;
    font-size: 12px;
    display: flex
;
    align-items: center;
    padding-left: 15px;
    color: #a57c00;
    height: 40px;
    position: absolute;
    width: 96%;
    bottom: 0;
}

  .productswap1 {
    width: 140px !important;
    height: 80px;
  }

  .product-detail-button-theme3-cart-button {
    font-size: 13px;
  }

  .Card-product-type-1-bottom {
    width: 65%;
  }

  .HeartSaveBtn2 {
    right: 35px;
  }

  .productswap2 {
    width: 150px !important;
    height: 80px;
  }

  .productswap3 {
    width: 140px !important;
    height: 80px;
  }

  .card-product-image-1 {
    height: 125px;
  }

  .card-product-details-1 {
    text-align: left;
    margin-left: 13px;
    margin-right: 5px;
  }

  .product-rating-box {
    bottom: 2px;
  }

  .Card-product-type-1-img {
    width: 30%;
    height: auto !important;
    margin: 0;
    background-size: 90%;
  }

  .card-product-name {
    font-size: 13px;
    line-height: 15px;
  }

  .card-product-price {
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
  }

  .card-product-price-2 {
    font-size: 11px;
    line-height: 12px;
    margin-top: 0px;
  }

  .card-product-desc {
    font-size: 10px;
    margin-top: 5px;
    line-height: 13px;
  }

  .product-rating-box {
    width: 80px;
    padding: 1px;
    left: 15px;
    display: none;
  }

  .card-menu-favorite {
    position: absolute;
    top: 7px;
    font-size: 16px;
    right: 9px;
    z-index: 1;
    border: 0;
  }

  .Card-product-type-1-top {
    min-height: 90px;
    width: 40%;
  }

  .Card-product-type-1-rating-color-box {
    display: flex;
    justify-content: space-between;
    width: 87%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3px;
    margin-top: 0;
  }

  .Card-product-type-1-end {
    height: 25px;
    background: linear-gradient(90deg, $Theme-color, lighten($Theme-color, 8%));
    margin-top: 5px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    font-weight: 500;
    font-size: 9px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    position: absolute;
    color: rgb(255, 255, 255);
    bottom: 0;
    justify-content: center;
    width: 97.2%;
  }

  .outline-coloroption-c2 {
    width: 13px;
    height: 13px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 50%;
    padding: 2px;
    margin-right: 3px;
  }

  .color-option-box1-c2 {
    background-color: rgb(0, 0, 0);
  }

  .color-option-box2-c2 {
    background-color: rgb(175, 175, 175);
  }

  .color-option-box3-c2 {
    background-color: rgb(0, 179, 255);
  }

  .color-option-box4-c2 {
    background-color: rgb(85, 85, 85);
  }

  .color-option-box5-c2 {
    background-color: rgb(182, 8, 8);
  }

  .color-option-box-main {
    width: 13px;
    height: 13px;
    border-radius: 50%;
  }

  .Card-product-type-1-rating-box {
    width: 85px;
    height: 11px;
    margin-bottom: 4px;
  }

  .productcart-bottom-txt-box {
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
  }

  .product-cart-3-txt-3 {
    font-size: 11px;
    margin-right: 3px !important;
  }

  .product-cart-3-txt-4 {
    font-size: 9px;
    margin-right: 6px !important;
  }

  .product-cart-3-txt-5 {
    font-size: 10px;
  }

  .product-cart-3-txt-6 {
    font-size: 10px;
  }

  .product-cart-3-txt-7 {
    font-size: 9px;
  }

  .Card-product-type-1-end1 {
    font-size: 9px;
    height: 23px;
    padding-left: 0px;
    position: absolute;
    bottom: 0;
    width: 99.5%;
    height: 30px;
  }

  .card-4-text-box-1 p {
    margin: 0;
  }

  .card-product-details-2 {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .card-4-text-box-1-txt-1 {
    color: rgb(85, 85, 85);
    font-size: 13px;
  }

  .card-4-text-box-1-txt-2 {
    color: rgb(85, 85, 85);
    font-size: 12px;
    font-weight: 100;
    line-height: 13px;
  }

  .card-4-text-box-1-txt-3 {
    color: rgb(0, 0, 65);
    font-size: 12px;
    margin-top: 3px !important;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 15px;
  }

  .cart-product-for-List a {
    flex-direction: row;
  }
}
